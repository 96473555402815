<template>
  <div class="app-content pt-3 p-md-3 p-lg-4">
    <div class="container-xl">
      <div class="row g-3 mb-4 align-items-center justify-content-between">
        <div class="col-auto">
          <h1 class="app-page-title mb-0">Agences</h1>
        </div>
        <div class="col-auto">
          <div class="page-utilities">
            <div
              class="row g-2 justify-content-start justify-content-md-end align-items-center"
            >
              <div class="col-auto">
                <form class="docs-search-form row gx-1 align-items-center">
                  <div class="col-auto">
                    <input
                      id="search-docs"
                      type="text"
                      name="searchdocs"
                      class="form-control search-docs"
                      placeholder="Recherche"
                    />
                  </div>
                </form>
              </div>
              <div class="col-auto">
                <select class="form-select w-auto">
                  <option selected="" value="option-1">Filtre</option>
                  <option value="option-1">Filtre 1</option>
                  <option value="option-2">Filtre 2</option>
                  <option value="option-3">Filtre 3</option>
                </select>
              </div>
              <div class="col-auto">
                <router-link to="/users/form" class="btn app-btn-primary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                    />
                  </svg>
                  Ajouter un utilisateur</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <div
          id="orders-all"
          class="tab-pane fade show active"
          role="tabpanel"
          aria-labelledby="orders-all-tab"
        >
          <div
            v-if="agencies"
            class="app-card app-card-orders-table shadow-sm mb-5"
          >
            <div class="app-card-body">
              <div class="table-responsive">
                <table
                  v-if="agencies.results"
                  class="table app-table-hover mb-0 text-left"
                >
                  <thead>
                    <tr>
                      <th class="cell">Ref agency</th>
                      <th class="cell">Nom</th>
                      <th class="cell">Prénom</th>
                      <th class="cell">Promoteur</th>
                      <th class="cell">Poste</th>
                      <th class="cell">Agence</th>
                      <th class="cell">Téléphone</th>
                      <th class="cell">Mail</th>
                      <th class="cell">N° Permis liés</th>
                      <th class="cell">Date de dernier échange</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="agency in agencies.results" :key="agency.id">
                      <td class="cell"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-else class="mb-3">
            <span>Aucune agence enregistrée</span>
          </div>
          <nav class="app-pagination">
            <ul class="pagination justify-content-center">
              <li class="page-item disabled">
                <a class="page-link" href="" tabindex="-1" aria-disabled="true"
                  >Précedent</a
                >
              </li>
              <li class="page-item active">
                <a class="page-link" href="">1</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="">2</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="">3</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="">Suivant</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Agencies",
  computed: {
    ...mapGetters(["agencies"]),
  },
  methods: {
    ...mapActions(["getAgencies"]),
    getAllAgencies() {
      this.getAgencies();
    },
  },
  mounted() {
    this.getAllAgencies();
  },
};
</script>